import { UserProfile } from './authentication';

export enum CALL_STATUS {
  OFFLINE = 'Offline',
  ON_A_CALL = 'On a Call',
  READY = 'Available',
  AFTER_CALL_WORK = 'After Call Work',
  BREAK = 'Break',
  LUNCH = 'Lunch-Dinner',
  TRAINING = 'Training',
  MEETING = 'Meeting',
  SPECIAL_PROJECT = 'Special Project',
  START_SYSTEM_APPLICATIONS = 'Start System Applications',
  ADDITIONAL_WRAP_UP = 'Additional Wrap-up',
}

export const SF_ADVOCATE_ROLE_ID = '00E5e000000DV0ZEAW';

export const PAID_CALL_STATUSES = [
  CALL_STATUS.ON_A_CALL,
  CALL_STATUS.AFTER_CALL_WORK,
  CALL_STATUS.TRAINING,
  CALL_STATUS.MEETING,
  CALL_STATUS.SPECIAL_PROJECT,
  CALL_STATUS.START_SYSTEM_APPLICATIONS,
  CALL_STATUS.ADDITIONAL_WRAP_UP,
  CALL_STATUS.READY,
];

export const AVAILABLE_FOR_CALL_STATUSES = PAID_CALL_STATUSES.filter(
  (s) => s !== CALL_STATUS.TRAINING && s !== CALL_STATUS.MEETING,
);

export interface RepresentativeStatus {
  user_id: string;
  tags: string[];
  status_name: CALL_STATUS;
  name: string;
}

export interface AdvocateCallCenterStatus {
  externalIdentifier: string;
  isActive: boolean;
  createdOn: Date;
  status: string;
  statusStart: Date;
  statusEnd: Date;
  externalUsername: string;
  externalUserId: string;
  email: string;
  statusDuration: number;
  uuid: string;
  teamName: string;
  teamId: string;
  ringGroups: string[];
}

export interface AvailableRecording {
  createdAt: string;
  index: number;
  duration: number;
  recordingId: string;
  isLastRecording: boolean;
}

export interface AdvocatePaidTime {
  id: string;
  callStatuses: Array<{ status: string; statusDuration: number }>;
  day: number;
  email: string;
  endWorkingDay: Date;
  month: number;
  startWorkingDay: Date;
  weekNumber: number;
  workedTime: number;
  year: number;
  scheduledSlots: Array<{ slots: Date }>;
  userInfo?: UserProfile;
}

export enum REPRESENTATIVE_LEVEL {
  LEVEL_1 = 'Level 1',
  LEVEL_2 = 'Level 2',
  LEVEL_3 = 'Level 3',
  LEVEL_4 = 'Level 4',
  LEVEL_5 = 'Level 5',
  LEVEL_6 = 'Level 6',
  LEVEL_7 = 'Level 7',
  CONTACTOR = 'Contactor',
}

export const MINIMUM_ACCEPTED_RATIO_OF_OPTIMAL_LEAD_TAKEN_PER_HOUR = 0.8;
export const MAXIMUM_ACCEPTED_RATIO_OF_OPTIMAL_LEAD_TAKEN_PER_HOUR = 1.2;
export const MINIMUM_HOURS_BEFORE_LEADS_TAKEN_METRIC_IS_USEFUL = 3;
export const OPTIMAL_NUMBER_OF_LEAD_TAKEN_PER_HOUR_FOR_ADVOCATE_LEVEL_1 = 0;
export const OPTIMAL_NUMBER_OF_LEAD_TAKEN_PER_HOUR_FOR_ADVOCATE_LEVEL_2 = 1.7;
export const OPTIMAL_NUMBER_OF_LEAD_TAKEN_PER_HOUR_FOR_ADVOCATE_LEVEL_3 = 2.5;
export const OPTIMAL_NUMBER_OF_LEAD_TAKEN_PER_HOUR_FOR_ADVOCATE_LEVEL_4_AND_ABOVE = 3.3;
export const MINIMUM_LEAD_TAKEN_PER_HOUR_CONTACTOR = 5;
